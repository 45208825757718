import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { App, Spin } from 'antd';
import { Suspense } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/styles/GlobalStyles.scss';
import ErrorBoundary from './components/ErrorBoundary';
import { BaseRoutes } from './routes';
import { persistor, store } from './store';

import '@/assets/styles/app.scss';
import DashboardProvider from './components/hoc/DashboardContext';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

const MyApp = () => {
	return (
		<div className='app'>
			<DashboardProvider>
				<App>
					<Provider store={store}>
						<Suspense fallback={<Spin className='SuspenseLoader' size='large' />}>
							<PersistGate
								loading={<Spin className='SuspenseLoader' size='large' />}
								persistor={persistor}
							>
								<QueryClientProvider client={queryClient}>
									<ErrorBoundary>
										<GoogleReCaptchaProvider
											reCaptchaKey={import.meta.env.VITE_API_CAPTCHA_KEY ?? ''}
										>
											<BaseRoutes />
										</GoogleReCaptchaProvider>
									</ErrorBoundary>
								</QueryClientProvider>
							</PersistGate>
						</Suspense>
					</Provider>
				</App>
			</DashboardProvider>
		</div>
	);
};

export default MyApp;
