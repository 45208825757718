import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import 'nprogress/nprogress.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import MyApp from './App';
import { config } from './config/theme';
import './config/translate';

Sentry.init({
	dsn: 'https://2095bb62702a830bbb5d70f6ab1bd36d@o4505592159469568.ingest.sentry.io/4505661616357376',
	enabled: process.env.NODE_ENV === 'production',
	environment: process.env.NODE_ENV,
	integrations: [
		new Sentry.BrowserTracing({
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new Sentry.Replay(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,
	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const element = document.getElementById('root');
const root = createRoot(element as HTMLElement);
root.render(
	<React.StrictMode>
		<ConfigProvider theme={config}>
			<MyApp />
		</ConfigProvider>
	</React.StrictMode>
);
