import type { ThemeConfig } from 'antd';

export const config: ThemeConfig = {
	token: {
		colorPrimary: '#16C829',
		colorSuccess: '#16c829',
		colorPrimaryActive: '#16c829',
		colorPrimaryText: '#16c829',
		colorPrimaryTextActive: '#16c829',
		colorLinkActive: '#16c829',
		colorLink: '#16c829',
	},
	components: {
		Card: {
			borderRadiusLG: 20,
		},
		Table: {
			borderRadius: 20,
			borderRadiusLG: 20,
		},
		Button: {
			colorPrimary: '#E5FDE6',
			colorTextLightSolid: '#16C829',
			colorPrimaryBorder: '#16C829',
		},
	},
};
