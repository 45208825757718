import { IUser } from '@/utils/interface/user';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type AuthState = {
	user: IUser;
	permissions: string[];
	isGoogleAuthConnected: boolean;
};

const initialState: AuthState = {
	user: {
		createdDate: '',
		updatedDate: '',
		id: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		balance: 0,
		point: 0,
		isActive: true,
		fullName: '',
		hasFirstDeposit: false,
		hideTopUp: false,
		invoiceMembership: '',
		notificationToken: '',
		pinKey: null,
		membership: {
			createdDate: '',
			updatedDate: '',
			id: 0,
			name: '',
			price: 0,
		},
	},
	permissions: [
		'DASHBOARD',
		'TRANSACTION',
		'PRODUCT',
		'DEPOSIT',
		'MUTATION',
		'API_INTEGRATION',
		'SETTINGS',
	],
	isGoogleAuthConnected: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<IUser>) => {
			state.user = action.payload;
		},
		removeUser: (state) => {
			state.user = initialState.user;
		},
		setIsGoogleAuthConnected: (state, action) => {
			state.isGoogleAuthConnected = action.payload;
		},
	},
});

export const { setUser, removeUser, setIsGoogleAuthConnected } = authSlice.actions;

export default authSlice;
