import { lazy } from 'react';
import { PUBLIC_ROUTES } from './paths';

export const publicRoutes = [
	{
		path: PUBLIC_ROUTES.SIGNIN,
		Component: lazy(() => import('@/pages/SignIn')),
	},
	{
		path: PUBLIC_ROUTES.REGISTER,
		Component: lazy(() => import('@/pages/Register')),
	},
	{
		path: PUBLIC_ROUTES.FORGOT_PASSWORD,
		Component: lazy(() => import('@/pages/ForgotPassword')),
	},
	{
		path: PUBLIC_ROUTES.FORGOT_PASSWORD_VERIFY,
		Component: lazy(() => import('@/pages/ForgotPasswordVerify')),
	},
	{
		path: PUBLIC_ROUTES.CONFIRMATION,
		Component: lazy(() => import('@/pages/Confirmation')),
	},
	{
		path: PUBLIC_ROUTES.TWOFA,
		Component: lazy(() => import('@/pages/TwoFA')),
	},
	{
		path: PUBLIC_ROUTES.GOOGLEAUTHFA,
		Component: lazy(() => import('@/pages/GoogleAuth')),
	},
	{
		path: PUBLIC_ROUTES.VERIFICATIONS,
		Component: lazy(() => import('@/pages/Verifications')),
	},
	{
		path: PUBLIC_ROUTES.RESET_PASSWORD,
		Component: lazy(() => import('@/pages/ResetPassword')),
	},
];
