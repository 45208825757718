export const PUBLIC_ROUTES = {
	AUTH: 'auth',
	SIGNIN: 'login',
	REGISTER: 'register',
	CONFIRMATION: 'confirmation',
	TWOFA: 'twofa',
	GOOGLEAUTHFA: 'google-auth',
	VERIFICATIONS: 'verifications',
	FORGOT_PASSWORD: 'forgot-password',
	FORGOT_PASSWORD_VERIFY: 'forgot-password-verify',
	RESET_PASSWORD: 'reset-password',
	SEARCH_INVOICE: 'search-invoice',
	INVOICE_PAGE: 'search-invoice/:invoiceId',
};

export const PRIVATE_ROUTES = {
	PANEL: 'panel',
	DASHBOARD: 'dashboard',
	TRANSACTION: 'transaction',
	TRANSACTION_DETAIL: 'transaction/:invoiceId',
	PRODUCT: 'product',
	PRODUCT_DETAIL: 'product/:slug',
	DEPOSIT: 'deposit',
	DEPOSIT_DETAIL: 'deposit/:invoiceId',
	MUTATION: 'mutation',
	INTEGRATION_API: 'integration',
	SETTINGS: 'settings',
	HELPDESK: 'helpdesk',
};
