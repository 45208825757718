import { ReactNode } from 'react';
import { ErrorBoundary as ErrorBoundaryHandler } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';

interface ErrorProps {
	children: ReactNode;
}

const ErrorBoundary = ({ children }: ErrorProps) => {
	return (
		<ErrorBoundaryHandler
			FallbackComponent={ErrorFallback}
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onReset={() => {}}
			onError={(error, errorInfo) => {
				console.error('Uncaught error:', error, errorInfo);
			}}
		>
			{children}
		</ErrorBoundaryHandler>
	);
};

export default ErrorBoundary;
