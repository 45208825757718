// DashboardContext.js
import { Grid } from 'antd';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

const { useBreakpoint } = Grid;
interface DashboardContextProps {
	isCollapsed: boolean;
	setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);

export const useDashboardContext = () => {
	const context = useContext(DashboardContext);
	if (!context) {
		throw new Error('useDashboardContext must be used within a DashboardProvider');
	}
	return context;
};

interface DashboardProviderProps {
	children: ReactNode;
}

const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
	const breakpoint = useBreakpoint();
	const [isCollapsed, setCollapsed] = useState(false);

	useEffect(() => {
		// Set isCollapsed based on breakpoint.xs when component first renders
		setCollapsed(breakpoint.xs ? true : false);
	}, [breakpoint.xs]);

	return (
		<DashboardContext.Provider value={{ isCollapsed, setCollapsed }}>
			{children}
		</DashboardContext.Provider>
	);
};

export default DashboardProvider;
