import { lazy } from 'react';
import { PRIVATE_ROUTES } from './paths';

export const privateRoutes = [
	{
		path: PRIVATE_ROUTES.DASHBOARD,
		Component: lazy(() => import('@/pages/Dashboard')),
	},
	{
		path: PRIVATE_ROUTES.TRANSACTION,
		Component: lazy(() => import('@/pages/Transaction/Transaction')),
	},
	{
		path: PRIVATE_ROUTES.TRANSACTION_DETAIL,
		Component: lazy(() => import('@/pages/Transaction/TransactionDetail')),
	},
	{
		path: PRIVATE_ROUTES.PRODUCT,
		Component: lazy(() => import('@/pages/Product/Product')),
	},
	{
		path: PRIVATE_ROUTES.PRODUCT_DETAIL,
		Component: lazy(() => import('@/pages/Product/ProductDetail')),
	},
	{
		path: PRIVATE_ROUTES.DEPOSIT,
		Component: lazy(() => import('@/pages/Deposit/Deposit')),
	},
	{
		path: PRIVATE_ROUTES.DEPOSIT_DETAIL,
		Component: lazy(() => import('@/pages/Deposit/DepositDetail')),
	},
	{
		path: PRIVATE_ROUTES.MUTATION,
		Component: lazy(() => import('@/pages/Mutation')),
	},
	{
		path: PRIVATE_ROUTES.INTEGRATION_API,
		Component: lazy(() => import('@/pages/Integration')),
	},
	{
		path: PRIVATE_ROUTES.SETTINGS,
		Component: lazy(() => import('@/pages/Settings')),
	},
	{
		path: PRIVATE_ROUTES.HELPDESK,
		href: 'https://api.whatsapp.com/send?phone=6282125411551&text=Hallo%20Admin%20Bisnis%20VocaGame%20saya%20ingin%20bertanya%20terkait%20product%20bisnis.vocagame.com',
		Component: '',
	},
];
